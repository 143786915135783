<template>
  <div>
    <vx-card title="Politique vie privée, confidentialité et de sécurité" class="mb-4">
      <div slot="no-body">
        <div class="p-8">
          <p v-html="content"></p>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: 'Politique vie privée, confidentialité et de sécurité',
    //titleTemplate: '%s | Juste Test'
  },
  data() {
    return {
      loading: false,
      content: "",
      error: false
    };
  },
  mounted() {
    this.fetchApplyGetPolicy();
  },
  methods: {
    ...mapActions("general", ["applyGetSetting"]),
    acceptAlert(data) {
      let newdata = {...data}
      this.$vs.notify(newdata);
    },
    async fetchApplyGetPolicy() {
      this.loading = true;
      let data = {
        name: "content_policy"
      };
      try {
		  
        let res = await this.applyGetSetting(data);
		if (res.data.value) this.content = res.data.value;

        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({
              name: "Login"
            });
          } else {
            this.acceptAlert(
              "danger",
              "Politique Juridique",
              "Une erreur s'est produite"
            );
          }
        }
        this.error = true;
      }
    }
  }
};
</script>
